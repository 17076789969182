import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import TextToggle from './TextToggle';
import '../css/style.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';

function CreatePost() {
  const API_URL = process.env.REACT_APP_API_URL;
  const CHAT_KEY = process.env.REACT_APP_CHAT_KEY;
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, postLanguage } = location.state || {};
  const { niche, audience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, ideas, setIdeas, selectedLanguage, setSelectedLanguage } = useTheme();
  const [postContent, setPostContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (theme) { 
      generatePost(theme); 
    }
  }, [theme]); 

  const generatePost = async (theme) => {
    setLoading(true);

    setProgress(0); 
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100)); 
    }, 150); 

    try {
      const contentParts = [];

      if (niche) contentParts.push(`Ниша: ${niche}`);
      if (audience) contentParts.push(`Аудитория: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Особенности аудитории: ${audienceCharacteristics}`);
      if (contentGoal) contentParts.push(`Цель: ${contentGoal}`);
      if (textTone) contentParts.push(`Стиль и тон: ${textTone}`);
      if (productService) contentParts.push(`Продукт или услуга: ${productService}`);

      const contentQuery = contentParts.join(', ');

      const languageToUse = postLanguage || selectedLanguage;

      const languagePart = languageToUse === 'ru'
        ? 'на русском языке'
        : languageToUse === 'lv'
        ? 'на латышском языке'
        : languageToUse === 'es'
        ? 'на испанском языке'
        : 'на английском языке';

      const response = await axios.post( `${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "Вы являетесь помощником, специализирующимся на создании идей для постов в Instagram." },
          { role: "user", content: `Тебе нужно постараться и создать пост на тему "${theme}". На основе: ${contentQuery}. Весь текст обязательно напиши ${languagePart}. Сделай отличный результат, чтобы я мог гордиться!` }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      const newContent = response.data.choices[0].message.content;
      const cleanedContent = cleanText(newContent); 
      setPostContent(cleanedContent);
      
      savePost(theme, cleanedContent);

    } catch (error) {
      setPostContent(t('error_while_processing') + error.message);
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    return text.replace(/\*/g, '').replace(/###/g, '');
  };

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = postContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy'); 
    document.body.removeChild(textArea);
    setCopySuccess(t('text_copied')); 
  };

  const savePost = async (theme, content) => {
    try {
      const response = await axios.post(`${API_URL}/posts`, {
        theme_name: theme,
        content: content
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  return (
    <div className="post_container">
      <span className="post_container_back_link" onClick={() => navigate(-1)}>{t('back')}</span>

      <h2>{t('post_result_heading')} <TextToggle text={theme} /></h2>

      {loading && (
        <div className="loading-progress-bar">
          <ProgressBar animated now={progress} label={`${progress}%`} /> 
        </div>
      )}

      {postContent && <div className="post-preview">{postContent}</div>}

      {postContent && (
        <button onClick={handleCopyText}>{t('copy_text')}</button> 
      )}

      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
}

export default CreatePost;