import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext'; // Импорт функции использования контекста
import LanguageSwitcher from '../components/LanguageSwitcher'; // Импорт переключателя языков

const Navbar = () => {
  const { user, logout } = useAuth();
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng); // Меняем язык
    localStorage.setItem('selectedLanguage', lng); // Сохраняем выбранный язык в локальное хранилище
  };

  const isAdmin = user?.admin;

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to={user ? '/projects' : '/'}>
          <img src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/ai.png" alt="Logo" className="navbar-logo" />
          CopyBrain
        </Link>
      </div>

      <div className={`menu-icon ${isOpen ? 'hide-icon' : ''}`} onClick={toggleMenu}></div>

      <div className={isOpen ? "nav-menu active" : "nav-menu"}>
        <div className="menu-close-icon" onClick={toggleMenu}></div>

        <div className="menu">
          {user && <NavLink to="/projects" onClick={toggleMenu} className={({ isActive }) => isActive ? 'active' : ''}>{t('projects')}</NavLink>}
          {user && <NavLink to="/education/courses/1" onClick={toggleMenu} className={({ isActive }) => isActive ? 'active' : ''}>{t('game')}</NavLink>}
          {user && <NavLink to="/profile" onClick={toggleMenu} className={({ isActive }) => isActive ? 'active' : ''}>{t('profile')}</NavLink>}
          {user && <NavLink to="/billing" onClick={toggleMenu} className={({ isActive }) => isActive ? 'active' : ''}>{t('billing')}</NavLink>}
          {user && <NavLink to="/contact-form" onClick={toggleMenu} className={({ isActive }) => isActive ? 'active' : ''}>{t('ask_question')}</NavLink>}
        </div>

        <div className="user-info">
          {isAdmin && <NavLink to="/admin-users" onClick={toggleMenu} className={({ isActive }) => isActive ? 'active' : ''}>{t('admin_panel')}</NavLink>}
          {isAdmin && <NavLink to="/admin-questions" onClick={toggleMenu} className={({ isActive }) => isActive ? 'active' : ''}>{t('questions_list')}</NavLink>}
          {isAdmin && <NavLink to="/courses" onClick={toggleMenu} className={({ isActive }) => isActive ? 'active' : ''}>{t('admin_courses')}</NavLink>}
        </div>

        <div className="user-info">
          {user ? (
            <div className="user-info-inner">
              <button onClick={() => {logout(); toggleMenu();}}>{t('logout')}</button>
            </div>
          ) : (
            <div className="user-info-inner">
              <Link to="/signup" onClick={toggleMenu}>{t('signUp')}</Link>
              <Link to="/login" onClick={toggleMenu}>{t('login')}</Link>
            </div>
          )}

          <LanguageSwitcher onLanguageChange={handleLanguageChange} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
