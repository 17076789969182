import React, { createContext, useContext, useState } from 'react';

// Контекст для хранения данных
const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [niche, setNiche] = useState('');
  const [audience, setAudience] = useState('');
  const [contentGoal, setContentGoal] = useState('');
  const [textTone, setTextTone] = useState('');
  const [audienceCharacteristics, setAudienceCharacteristics] = useState('');
  const [productService, setProductService] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Состояние для чекбокса
  const [ideas, setIdeas] = useState([]);

  return (
    <ThemeContext.Provider value={{ projectId, setProjectId, projectName, setProjectName, niche, setNiche, audience, setAudience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, selectedLanguage, setSelectedLanguage, ideas, setIdeas }}>
      {children}
    </ThemeContext.Provider>
  );
};
