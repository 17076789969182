import React, { useState } from 'react';

const TextToggle = ({ text, maxLength = 150 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text.slice(0, maxLength) + (text.length > maxLength ? '... ' : '');

  return (
    <div>
      <p className="mb-0">{displayText}</p>
      {text.length > maxLength && (
        <span className="show_more_link" onClick={toggleText}>
          {isExpanded ? 'Скрыть' : 'Показать полностью'}
        </span>
      )}
    </div>
  );
};

export default TextToggle;
