import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import TextToggle from './TextToggle';
import '../css/style.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';

function CreateReel() {
  const API_URL = process.env.REACT_APP_API_URL;
  const CHAT_KEY = process.env.REACT_APP_CHAT_KEY;
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, duration, postLanguage, reelFormat, reelCta } = location.state || {};
  const { niche, audience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, ideas, setIdeas, selectedLanguage, setSelectedLanguage } = useTheme();
  const [reelContent, setReelContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (theme) { // Убедитесь, что вызов только при наличии темы
      generateReel(theme, duration, reelFormat, reelCta); // Убедитесь, что вызывается только один раз
    }
  }, [theme, duration, reelFormat, reelCta]); // Проверка правильных зависимостей

  const generateReel = async (theme, duration, reelFormat, reelCta) => {
    setLoading(true);

    setProgress(0); // Сбрасываем прогресс
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100)); // Обновляем прогресс каждые 10%
    }, 150); // Интервал обновления прогресса

    try {
       const contentParts = [];

      // Добавляем в запрос только заполненные поля
      if (niche) contentParts.push(`Ниша: ${niche}`);
      if (audience) contentParts.push(`Аудитория: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Особенности аудитории: ${audienceCharacteristics}`);
      if (contentGoal) contentParts.push(`Цель: ${contentGoal}`);
      if (textTone) contentParts.push(`Стиль и тон: ${textTone}`);
      if (productService) contentParts.push(`Продукт или услуга: ${productService}`);

      const contentQuery = contentParts.join(', ');

      const languageToUse = postLanguage || selectedLanguage;

      const languagePart = languageToUse === 'ru'
        ? 'на русском языке'
        : languageToUse === 'lv'
        ? 'на латышском языке'
        : languageToUse === 'es'
        ? 'на испанском языке'
        : 'на английском языке';

      // Заголовки
      const titlesList = [
        "Что случится, если ты…", "Новый способ …", "Как стать … за … дней?", "Как гарантированно … с / на …?", 
        "Представь, что…", "Мысль, которая…", "Настоящий (ая) … не про …", "Всего один … и ты …", 
        "Существует один (одна) …", "Почему я не… (верю в / не согласен с)", "Честное мнение о /про …", 
        "Идеальное решение для…", "Это не правило, это закон …!", "Где …, чтобы …", "Оказывается …", 
        "Что все-таки лучше: … или …", "Этот рилс сохранит тебе …", "Топ стратегия в …", 
        "Главный вопрос в …", "Как сделать … на …", "Как на самом деле … ?", "… требует этих …", 
        "Как общаться с …", "Как не надо …", "Это нормально …", "Эксклюзив для …", "Почему я до сих пор не …", 
        "… для всех, кто …", "… неизвестных факта о …", "Единственный способ …", 
        "Сделай это сейчас, если хочешь …", "Все думают, что …, но нет", "Грустная правда о …", 
        "Я не мог …, пока не понял это…", "Приготовься! Через это проходят все, кто…", 
        "Лучший пример, как …", "Пока все …, я …", "Теперь все твои…", "Куда точно стоит/не стоит…", 
        "Мощная/ый … для …", "Мой любимый кейс (в)...", "Спроси себя об этом, если …", 
        "Это не самый этичный лайфхак для…", "Ты не …, пока (не) …", "Объясню, почему…", 
        "Два универсальных совета для…", "Вот почему самые … – это …", "Спорим, ты никогда не…", 
        "Вредный совет: как …", "Скажи спасибо …, если ….", "Если у тебя … – срочно …", 
        "… – худшее решение для …", "Простая формула (для)…", "С чего начинается … ?", 
        "Как за … (минуту/час/год…)", "Хочешь …? Забудь о (про)…", "Самая большая ошибка…", 
        "Как я отвечаю на вопрос: “...?”", "Главное, что мешало мне…", "Ты будешь …, пока не …"
      ].join(', ');

      // const model = user?.first_user ? "gpt-4-turbo-2024-04-09" : "gpt-3.5-turbo";

      const response = await axios.post(`${API_URL}/openai_proxy`, {
        // model: model,
        // model: "gpt-3.5-turbo",
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "Вы являетесь помощником, специализирующимся на создании сценариев для коротких видео в Instagram." },
          { role: "user", content: `Тебе нужно постараться и создать сценарий короткого видео на тему "${theme}". На основе: ${contentQuery}. В сценарии использую заголовк из списка: ${titlesList}. Длительность короткого видео: ${duration}. Добавь примерную разбивку по секундам. Формат короткого видео: ${reelFormat}. Добавь в конце сценария призыв к действию: ${reelCta}. Весь текст обязательно напиши ${languagePart}. Сделай отличный результат, чтобы я мог гордиться!` }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      const newContent = response.data.choices[0].message.content;
      const cleanedContent = cleanText(newContent); // Очистка текста
      setReelContent(cleanedContent);
      
      saveReel(theme, cleanedContent);

    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      setReelContent(t('error_while_processing') + error.message);
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    return text.replace(/\*/g, '').replace(/###/g, '');
  };

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = reelContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy'); // Копируем текст в буфер обмена
    document.body.removeChild(textArea);
    setCopySuccess(t('text_copied')); // Сообщение об успешном копировании
  };

  const saveReel = async (theme, content) => {
    try {
      const response = await axios.post(`${API_URL}/reels`, {
        theme_name: theme,
        content: content
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  return (
    <div className="post_container">
      <span className="post_container_back_link" onClick={() => navigate(-1)}>{t('back')}</span>

      <h2>{t('reel_result_heading')} <TextToggle text={theme} /></h2>

      {loading && (
        <div className="loading-progress-bar">
          <ProgressBar animated now={progress} label={`${progress}%`} /> 
        </div>
      )}

      {reelContent && <div className="post-preview">{reelContent}</div>}

      {reelContent && (
        <button onClick={handleCopyText}>{t('copy_text')}</button> 
      )}

      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
}

export default CreateReel;