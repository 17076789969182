import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import { useStory } from '../StoryContext';
import TextToggle from './TextToggle';
import '../css/style.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';

function CreateStory() {
  const API_URL = process.env.REACT_APP_API_URL;
  const CHAT_KEY = process.env.REACT_APP_CHAT_KEY;
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, duration, postLanguage } = location.state || {};

  const { projectId, niche, audience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, ideas, setIdeas, selectedLanguage, setSelectedLanguage } = useTheme();

  const { storyGoal, setStoryGoal, storyTheme, setStoryTheme, storyCharacter, setStoryCharacter, storyProblem, setStoryProblem, storySolution, setStorySolution, storyResult, setStoryResult, storyEmotions, setStoryEmotions, storyMoments, setStoryMoments, storyCta, setStoryCta, storyQty, setStoryQty, selectedStoryLanguage, setSelectedStoryLanguage } = useStory();

  const [storyContent, setStoryContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (storyTheme) {
      generateStory(storyTheme);
    }
  }, [theme, duration]);

  const generateStory = async (storyTheme) => {
    setLoading(true);

    setProgress(0);
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100));
    }, 150);
    try {
      const contentParts = [];

      if (niche) contentParts.push(`Ниша: ${niche}`);
      if (audience) contentParts.push(`Аудитория: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Особенности аудитории: ${audienceCharacteristics}`);
      if (productService) contentParts.push(`Продукт или услуга: ${productService}`);

      const contentQuery = contentParts.join(', ');

      const storyParts = [];

      if (storyGoal) storyParts.push(`Цель сторителлинга: ${storyGoal}`);
      if (storyTheme) storyParts.push(`Тема: ${storyTheme}`);
      if (storyCharacter) storyParts.push(`Главные герои истории: ${storyCharacter}`);
      if (storyProblem) storyParts.push(`Проблема, с которой сталкивается главный герой в начале истории: ${storyProblem}`);
      if (storySolution) storyParts.push(`Решение проблемы: ${storySolution}`);
      if (storyResult) storyParts.push(`Изменения после использования предложенного решения: ${storyResult}`);
      if (storyEmotions) storyParts.push(`Эмоции, которые нужно вызвать у аудитории: ${storyEmotions}`);
      if (storyMoments) storyParts.push(`Уникальные или интересные детали: ${storyMoments}`);
      if (storyCta) storyParts.push(`Желаемое действие аудитории после прочтения истории: ${storyCta}`);
      if (storyQty) storyParts.push(`Количество сторис необходимое для полного рассказа истории: ${storyQty}`);

      const storyQuery = storyParts.join(', ');

      const languageToUse = selectedStoryLanguage || selectedLanguage;

      const languagePart = languageToUse === 'ru'
        ? 'на русском языке'
        : languageToUse === 'lv'
        ? 'на латышском языке'
        : languageToUse === 'es'
        ? 'на испанском языке'
        : 'на английском языке';

      const response = await axios.post(`${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "Вы являетесь помощником, специализирующимся на создании сторителлинга в Instagram." },
          { role: "user", content: `Тебе нужно постараться и создать сторителлинг используя эти данные: ${storyQuery}. Как информацию о проекте учитывай: ${contentQuery}. Весь текст обязательно напиши ${languagePart}. Сделай отличный результат, чтобы я мог гордиться!` }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      const newContent = response.data.choices[0].message.content;
      const cleanedContent = cleanText(newContent); // Очистка текста
      setStoryContent(cleanedContent);
      
      saveStory(storyTheme, cleanedContent);

    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      setStoryContent(t('error_while_processing') + error.message);
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    return text.replace(/\*/g, '').replace(/###/g, '');
  };

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = storyContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy'); // Копируем текст в буфер обмена
    document.body.removeChild(textArea);
    setCopySuccess(t('text_copied')); // Сообщение об успешном копировании
  };

  const saveStory = async (storyTheme, content) => {
    try {
      const response = await axios.post(`${API_URL}/projects/${projectId}/stories`, {
        story_theme: storyTheme,
        story_text: content
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  return (
    <div className="post_container">
      <span className="post_container_back_link" onClick={() => navigate(-1)}>{t('back')}</span>

      <h2>{t('story_result_heading')} <TextToggle text={storyTheme} /></h2>

      {loading && (
        <div className="loading-progress-bar">
          <ProgressBar animated now={progress} label={`${progress}%`} /> 
        </div>
      )}

      {storyContent && <div className="post-preview">{storyContent}</div>}

      {storyContent && (
        <button onClick={handleCopyText}>{t('copy_text')}</button> 
      )}

      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
}

export default CreateStory;