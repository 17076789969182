import React, { useState } from 'react';
import axios from 'axios';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ImagePreview from './ImagePreview';

// Настройки AWS S3 клиента
const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const ImageUploader = ({ onAnalysisComplete, onSaveComplete, projectId }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [image, setImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const token = localStorage.getItem('token');

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!image) {
      alert('Please select an image first');
      return;
    }

    setLoading(true);
    setProgress(0);

    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100));
    }, 150);

    const params = {
      Bucket: 'gnot-me-public',
      Key: `${Date.now()}_${image.name}`,
      Body: image,
      ContentType: image.type,
      ACL: 'public-read',
    };

    try {
      // Загрузка изображения в S3
      await s3Client.send(new PutObjectCommand(params));
      const imageUrl = `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.Key}`;

      setImageSrc(imageUrl);

      const localeLanguage = activeLanguage === 'ru'
        ? 'на русском языке'
        : activeLanguage === 'lv'
        ? 'на латышском языке'
        : activeLanguage === 'es'
        ? 'на испанском языке'
        : 'на английском языке';

      const response = await axios.post(`${API_URL}/analyze_image`, {
        image_url: imageUrl,
        analysis_language: localeLanguage
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const advice = response.data.choices[0].message.content.trim();
      onAnalysisComplete(advice, imageUrl);

      // Сохранение результата анализа и ссылки на изображение в Rails API
      const saveResponse = await axios.post(`${API_URL}/projects/${projectId}/analysis_results`, {
        image_url: imageUrl,
        analysis_result: advice
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      onSaveComplete(saveResponse.data);
    } catch (error) {
      console.error('Error uploading image or analyzing:', error);
      alert('Error uploading image or analyzing: ' + error.message);
    } finally {
      clearInterval(interval);
      setLoading(false);
    }
  };

  return (
    <div className="project_form mw-100 mt-3">
      <label htmlFor="project-name">{t('upload_image_for_analysis')}</label>

      <span className="dashboard_form_label_subtext">
        {t('upload_image_for_analysis_text')}
      </span>

      <div className="mt-2 mb-4" style={{color: "white"}}>
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </div>

      {loading ? (
        <div className="loading-progress-bar mt-4">
          <ProgressBar animated now={progress} label={`${progress}%`} />
        </div>
      ) : (
        <button type="button" className="save_button darker_bg w-100" onClick={handleUpload} disabled={loading}>
          {loading ? t('analyzing') : t('upload_analyze')}
        </button>
      )}
    </div>
  );
};

export default ImageUploader;
